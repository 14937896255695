<template>
	<v-layout column fill-height>
		<v-flex shrink>
			<WSection :title="$t('quickfolders-manager.quickfolder_name')">
				<w-text-input v-model="quickfolderModel.name" :label="$t('name')" maxlength="191" required />
			</WSection>
		</v-flex>
		<v-flex scroll-y>
			<WSection fill-height :title="$t('quickfolders-manager.quickfolders_metadatas')">
				<v-layout column fill-height>
					<v-flex scroll-y>
						<v-layout v-for="(metadata, index) in currentMetadatas" :key="metadata.id" align-center row>
							<v-flex pr-1 shrink>
								<v-autocomplete
									v-model="metadata.catalog_flag_id"
									:items="filteredMetadatas(metadata.id)"
									:label="$t('quickfolders-manager.type')"
									item-text="name"
									item-value="id"
								/>
							</v-flex>
							<v-flex grow px-1>
								<v-text-field v-model="metadata.value" :label="$t('quickfolders-manager.value')" maxlength="191" />
							</v-flex>
							<v-flex pl-1 shrink>
								<v-btn color="primary" icon small @click="addMetadata(index)">
									<v-icon small>add</v-icon>
								</v-btn>
								<w-btn-delete :disabled="currentMetadatas.length < 2" :flat="false" mini small @click="removeMetadata(index)" />
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</WSection>
		</v-flex>
		<v-flex v-for="(plugin, index) in plugins" :key="index" shrink>
			<component :is="plugin" v-model="quickfolderModel" />
		</v-flex>
		<v-flex shrink text-xs-center>
			<w-btn-delete v-if="quickfolderModel.id" @click="deleteQuickfolderModel" />
			<w-btn-save :disabled="!isValid" @click="save" />
		</v-flex>
	</v-layout>
</template>

<script>
import QuickFoldersModuleGuard from '@/mixins/ModulesGuards/QuickFolders/QuickFoldersModuleGuard'
import Validator from '@/mixins/Validator'

export default {
	name: 'QuickfolderModelForm',
	mixins: [QuickFoldersModuleGuard, Validator],
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			currentMetadatas: [],
			metadatas: [],
			quickfolderModel: {}
		}
	},
	computed: {
		isCreating: function () {
			return !this.value.id
		},
		isValid: function () {
			const currentMetadatas = this.currentMetadatas
			const quickfolderModel = this.quickfolderModel
			let result = true
			if (!quickfolderModel.name || quickfolderModel.name.trim().length === 0) {
				result = false
			}
			if (currentMetadatas.filter(metadata => metadata.catalog_flag_id).length === 0) {
				result = false
			}
			return result
		},
		plugins: function () {
			return this.service.getPluginsInQuickfolderModelForm()
		}
	},
	watch: {
		value: {
			handler: function (val) {
				if (val) {
					this.quickfolderModel = { ...val }
					if (!Array.isArray(val.metadatas) || val.metadatas.length === 0) {
						this.quickfolderModel.metadatas = [{}]
						this.currentMetadatas = [{}]
					} else {
						this.currentMetadatas = [...val.metadatas]
					}
				}
			},
			immediate: true
		}
	},
	created: function () {
		this.getMetadatas()
	},
	methods: {
		addMetadata: function (index = null) {
			if (index == null || (index === 0 && this.currentMetadatas.length === 1)) {
				this.currentMetadatas.push({})
			} else {
				this.currentMetadatas.splice(index + 1, 0, {})
			}
		},
		closeForm: function () {
			this.$emit('close')
		},
		createQuickfolderModel: function () {
			return this.service.createQuickfolderModel(this.quickfolderModel)
		},
		deleteQuickfolderModel: function () {
			this.$dialog
				.warning({
					text: this.$t('quickfolders-manager.delete_text', { name: this.value.name }),
					title: this.$t('quickfolders-manager.delete_title'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					if (res) {
						return this.service.deleteQuickfolderModel(this.quickfolderModel.id).then(() => {
							this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('quickfolders.actions.delete'))
							this.closeForm()
						})
					}
				})
		},
		filteredMetadatas: function (metadataId) {
			const forbiddenMetadatas = this.currentMetadatas
				.filter(metadata => {
					if (metadata.id === metadataId) {
						return true
					}
					return metadata.id
				})
				.map(metadata => metadata.id)
			return this.metadatas?.filter(metadata => {
				return metadataId === metadata.id || !forbiddenMetadatas.includes(metadata.id)
			})
		},
		getMetadatas: function () {
			return this.service.getMetadatas().then(metadatas => {
				this.metadatas = metadatas
			})
		},
		removeMetadata: function (index) {
			this.currentMetadatas.splice(index, 1)
		},
		save: function () {
			let promise = null
			this.quickfolderModel.metadatas = this.currentMetadatas.filter(metadata => metadata.catalog_flag_id)
			if (this.isCreating) {
				promise = this.createQuickfolderModel()
			} else {
				promise = this.updateQuickfolderModel()
			}
			return promise.then(quickfolderModel => {
				this.$emit('input', quickfolderModel)
				this.closeForm()
			})
		},
		updateQuickfolderModel: function () {
			return this.service.updateQuickfolderModel(this.quickfolderModel)
		}
	}
}
</script>
